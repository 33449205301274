<template>
  <div class="w-full">
    <div class="w-full flex flex-col bg-white rounded overflow-hidden min-h-96">
      <div class="grid grid-cols-1 lg:grid-cols-2 bg-white p-2">
        <div class="col-span-1 m-2 p-4 bg-gray-100">
          <h3 class="text-sm font-semibold">Import</h3>

          <div class="flex">
            <router-link
              to="/settings/import"
              class="w-full mt-4 pt-4 border-t border-gray-400"
            >
              Kontakte hochladen(.xslx)
            </router-link>
          </div>
        </div>
        <div class="col-span-1 m-2 p-4 bg-gray-100">
          <h3 class="text-sm font-semibold">Export</h3>

          <div class="flex">
            <div class="w-full mt-4 pt-4 border-t border-gray-400 ">
              <a
                @click.prevent="exportCSV"
                class="p-2 rounded bg-blue-dark text-white cursor-pointer"
              >
                Personal exportieren (.csv)
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { xhr } from "@/utils/axios"

export default {
  name: "import-export",
  data() {
    return {
      breadcrumbs: {
        title: [
          { crumb: "Allgemeine Einstellungen", link: "/settings" },
          { crumb: "Import / Export" },
        ],
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$emit("breadcrumbs", this.breadcrumbs)
    },
    exportCSV() {
      xhr.get("/personal/export/").then((response) => {
        const anchor = document.createElement("a")
        anchor.href =
          "data:text/csv;charset=utf-8," + encodeURIComponent(response.data)
        anchor.target = "_blank"
        anchor.download = `persona_export.csv`
        anchor.click()
      })
    },
  },
}
</script>

<style></style>
